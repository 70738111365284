<template>
  <div class="iframeWrap" @click="back">
    <nut-icon type="circle-cross" class="closeIcon" size="0.48rem"></nut-icon>
    <iframe :src="src" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  created() {
    this.src = this.$route.query.src;
  },
  data() {
    return {
      src: "",
    };
  },
  methods: {
    back() {
      this.$router.go("-1");
    },
  },
};
</script>

<style scoped>
.iframeWrap {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.closeIcon {
  position: absolute;
  top: 0.15rem;
  right: 0.3rem;
  z-index: 9999;
}
iframe {
  width: 100vw;
  height: 100vh;
}
</style>